import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AnimationClassNames, DefaultButton, Modal, PrimaryButton, Stack, Text, useTheme } from '@fluentui/react';
import {
    DeploymentData,
    Product,
    Mode,
    NewResourceGroup,
    NewAppRegistration,
    TRootPropsContext,
    ResourceGroup,
    Deployment,
} from 'projectum-product-deployment';
import { apiCalls } from '../../api/api';

interface IDeploymentModalProps {
    show: boolean;
    close: (success?: boolean) => void;
    area: string;
    debug?: boolean;
    title?: string;
    mode: Mode;
}

export const DeploymentModal: React.FC<IDeploymentModalProps> = ({ show, close, area, debug, title, mode }) => {
    const theme = useTheme();
    // const buttonStyles = useButtonStyles();
    const [installComplete, setInstallComplete] = useState(false);
    const [warnAboutIncompleteDeployment, setWarnAboutIncompleteDeployment] = useState(false);
    const [installStarted, setInstallStarted] = useState(false);
    const [appUrl, setAppUrl] = useState('');

    const onInstallComplete = useCallback((appUrl: string) => {
        setInstallComplete(true);
        setAppUrl(appUrl);
    }, []);

    const onInstallStarted = useCallback(() => {
        setInstallStarted(true);
    }, []);

    useEffect(() => {
        if (!show) {
            setWarnAboutIncompleteDeployment(false);
            setInstallComplete(false);
            setInstallStarted(false);
            setAppUrl('');
        }
    }, [show]);

    return show ? (
        <>
            <DeploymentModalInner area={area} title={title} debug={debug} onInstallComplete={onInstallComplete} mode={mode} onInstallStarted={onInstallStarted}>
                {/* <Stack horizontal horizontalAlign="end" styles={{ root: { position: 'absolute', bottom: 20, right: 20 } }}> */}
                <Stack horizontal horizontalAlign="end">
                    {debug && <DefaultButton onClick={() => close(true)}>Fake success</DefaultButton>}
                    {installComplete || !installStarted ? (
                        <PrimaryButton
                            styles={{
                                root: {
                                    borderRadius: theme.spacing.l1,
                                },
                            }}
                            onClick={() => close(true)}
                        >
                            Close
                        </PrimaryButton>
                    ) : (
                        <DefaultButton
                            styles={{
                                root: {
                                    borderRadius: theme.spacing.l1,
                                },
                            }}
                            onClick={() => setWarnAboutIncompleteDeployment(true)}
                        >
                            Cancel deployment
                        </DefaultButton>
                    )}
                </Stack>
                {warnAboutIncompleteDeployment && (
                    <Stack
                        verticalAlign="center"
                        horizontalAlign="center"
                        styles={{
                            root: {
                                //
                                position: 'fixed',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(255,255,255,0.8)',
                            },
                        }}
                        className={AnimationClassNames.fadeIn200}
                    >
                        <Stack
                            styles={{
                                root: {
                                    //
                                    height: 300,
                                    width: 400,
                                    backgroundColor: 'white',
                                    padding: 20,
                                    border: '1px solid rgba(0,0,0,0.2)',
                                    borderRadius: theme.spacing.l1,
                                },
                            }}
                            verticalAlign="space-between"
                        >
                            <Stack>
                                <Text as="p" variant="large">
                                    Are you sure?
                                </Text>
                                <Text as="p" variant="medium">
                                    <Text>
                                        <strong>This can break the current deployment process if it is already in progress</strong>
                                    </Text>
                                </Text>
                            </Stack>
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>
                                <PrimaryButton
                                    styles={{
                                        root: {
                                            borderRadius: theme.spacing.l1,
                                        },
                                    }}
                                    onClick={() => setWarnAboutIncompleteDeployment(false)}
                                >
                                    Continue deployment
                                </PrimaryButton>
                                <DefaultButton
                                    styles={{
                                        root: {
                                            borderRadius: theme.spacing.l1,
                                        },
                                    }}
                                    onClick={() => close(false)}
                                >
                                    Cancel deployment
                                </DefaultButton>
                            </Stack>
                        </Stack>
                    </Stack>
                )}
            </DeploymentModalInner>
            <Modal isOpen={Boolean(appUrl)} onDismiss={() => close(true)} isDarkOverlay={false}>
                <Stack verticalAlign="space-between" styles={{ root: { minHeight: '300px', padding: 10 } }}>
                    <Stack horizontalAlign="center" tokens={{ childrenGap: 20 }} grow={1} verticalAlign="center" styles={{ root: { margin: 20 } }}>
                        <Text variant="large">{title} was successfully deployed!</Text>
                        <a href={appUrl} target="_blank" rel="noreferrer">
                            <PrimaryButton text={`Go to ${title}`} />
                        </a>
                    </Stack>
                    <Stack horizontalAlign="end">
                        <PrimaryButton styles={{ root: { width: '100px' } }} onClick={() => close(true)}>
                            Close
                        </PrimaryButton>
                    </Stack>
                </Stack>
            </Modal>
        </>
    ) : null;
};

// const DeploymentModalInner: React.FC<Pick<IDeploymentModalProps, 'area' | 'debug' | 'title'> & { onInstallComplete: () => void }> = ({
const DeploymentModalInner: React.FC<
    Pick<IDeploymentModalProps, 'area' | 'debug' | 'title' | 'mode'> & { onInstallComplete: (appUrl: string) => void; onInstallStarted: () => void }
> = ({ area, debug, children, onInstallComplete, onInstallStarted, title, mode }) => {
    const theme = useTheme();

    useEffect(() => {
        const handler = (e: BeforeUnloadEvent) => {
            const confirmationMessage =
                'It looks like you have been editing something. ' +
                //
                'If you leave before saving, your changes will be lost.';

            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
        };
        window.addEventListener('beforeunload', handler);
        return () => {
            window.removeEventListener('beforeunload', handler);
        };
    }, []);

    const deploytmentProps = useMemo(() => {
        return {
            startDeployment: async (dep: DeploymentData) => {
                return apiCalls
                    .installationsDeployCreate(dep)
                    .then(res => res.data)
                    .catch(res => {
                        throw res.error;
                    });
            },
            getArmInfo: async () => {
                return apiCalls
                    .installationsSchemaList({ mode, product: area as Product })
                    .then(res => res.data)
                    .catch(res => {
                        throw res.error;
                    });
            },
            createResourceForSubscription: async (subId: string, resource: NewResourceGroup) => {
                return apiCalls
                    .installationsSubscriptionsResourcegroupsCreate(subId, resource)
                    .then(res => res.data)
                    .catch(res => {
                        throw res.error;
                    });
            },
            getLocationsForSubscription: async (subId: string) => {
                return apiCalls
                    .installationsSubscriptionsLocationsDetail(subId)
                    .then(res => res.data)
                    .catch(res => {
                        throw res.error;
                    });
            },
            getResourcesForSubscription: async (subId: string) => {
                return apiCalls
                    .installationsSubscriptionsResourcegroupsDetail(subId)
                    .then(res => res.data)
                    .catch(res => {
                        throw res.error;
                    });
            },
            getSubscriptions: async () => {
                return apiCalls
                    .installationsSubscriptionsList()
                    .then(res => res.data)
                    .catch(res => {
                        throw res.error;
                    });
            },
            createAppRegistration: async (appReg: NewAppRegistration) => {
                return apiCalls
                    .installationsAppregistrationCreate(appReg)
                    .then(res => res.data)
                    .catch(res => {
                        throw res.error;
                    });
            },
            getAppRegConsented: async (clientId: string) => {
                return apiCalls
                    .installationsAppregistrationDetail(clientId)
                    .then(res => res.data)
                    .catch(res => {
                        throw res.error;
                    });
            },
            getResourceGroupOwnerCheck: async (subId: string, resName: string) => {
                return apiCalls
                    .installationsSubscriptionsResourcegroupsOwnerDetail(subId, resName)
                    .then(() => {})
                    .catch(res => {
                        throw res.error;
                    });
            },
            createInsightsWorkspace: async (name: string, subscriptionId: string, resourceGroup: ResourceGroup) => {
                return apiCalls
                    .installationsSubscriptionsWorkspacesCreate(subscriptionId, name, resourceGroup)
                    .then(res => res.data)
                    .catch(res => {
                        throw res.error;
                    });
            },
            getInsightsWorkspacesSubscription: async (subscriptionId: string) => {
                return apiCalls
                    .installationsSubscriptionsDetail(subscriptionId)
                    .then(res => res.data)
                    .catch(res => {
                        throw res.error;
                    });
            },
            validateResourceName: async ({ name, provider, subscriptionId, type }) => {
                return apiCalls
                    .installationsValidateResourceCreate({ name, subscriptionId, type })
                    .then(res => res.data)
                    .catch(res => {
                        throw res.error;
                    });
            },
        } as Omit<TRootPropsContext, 'deploymentMode' | 'productName' | 'onSuccessfulInstall' | 'onInstallStarted'>;
    }, [area, mode]);

    return (
        <Modal isOpen className={AnimationClassNames.fadeIn200} topOffsetFixed={false} styles={{ main: { borderRadius: theme.spacing.l1 } }}>
            <Stack styles={{ root: { position: 'relative' } }}>
                <Stack
                    horizontalAlign="center"
                    tokens={{ childrenGap: 10, padding: 10 }}
                    styles={{
                        root: {
                            //
                            width: '80vw',
                            // heigth: '80vh',
                            transition: 'opacity 1s',
                            // opacity: iframeLoaded ? 1 : 0,
                        },
                    }}
                >
                    <Text variant="xxLarge">{title || area}</Text>
                    <div style={{ height: '80vh', width: '100%', position: 'relative' }}>
                        <Deployment
                            {...deploytmentProps}
                            deploymentMode={mode}
                            productName={area as Product}
                            onSuccessfulInstall={onInstallComplete}
                            onInstallStarted={onInstallStarted}
                        />
                    </div>
                </Stack>
            </Stack>
            <Stack styles={{ root: { position: 'absolute', bottom: 20, right: 20 } }}>{children}</Stack>
        </Modal>
    );
};
