import { makeStyles } from "@fluentui/react";


export const useButtonStyles = makeStyles(theme => {

    return {
        root: {
            borderRadius: theme.spacing.l1
        }
    }
})