import { MsalProvider } from '@azure/msal-react';
import { ErrorBoundary } from '../Components/ErrorBoundary';
import { apiCalls } from '../api/api';
import { PublicClientApplication } from '@azure/msal-browser';

import { useQuery } from '@tanstack/react-query';

export const AuthProvider: React.FC = ({ children }) => {
    const { data: msalInstance } = useQuery(
        ['MSAL'],
        async () => {
            const authOptions = await apiCalls.authOptionsList().then(response => response.data);
            return new PublicClientApplication({
                auth: {
                    clientId: authOptions.clientId!,
                    authority: (!authOptions.instance!.endsWith('/') ? authOptions.instance + '/' : authOptions.instance) + authOptions.tenantId!,
                },
            });
        },
        { suspense: true },
    );

    return (
        <ErrorBoundary>
            <MsalProvider instance={msalInstance!}>{children}</MsalProvider>
        </ErrorBoundary>
    );
};

// const LoadingSpinner = ({ message }: { message?: string }) => (
//     <Spinner size={SpinnerSize.large} label={message} styles={{ root: { position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 } }} />
// );
