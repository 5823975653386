import React, { useLayoutEffect, useMemo, useState } from 'react';
import { DefaultButton, makeStyles, Stack } from '@fluentui/react';
import { useCallback } from 'react';
import { replaceItemInArray } from 'pum-spark';
import { getTextFieldStyles } from '../styles/getTextFieldStyles';
import { DynamicTextField } from '../components/IDynamicTextField';
import { IField } from '../types/IField';
import { AccountContact } from '../../../api/generated/data-contracts';

export const getButtonStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#00C160',
        borderWidth: 0,
        height: 38,
        width: 'auto',
        margin: `${theme.spacing.l1} auto !important`,
        minWidth: 150,
        borderRadius: 25,
        boxShadow:
            '0 0.18rem 0.32rem rgba(0, 0, 0, 0.1), 0 0.32rem 0.64rem rgba(0, 0, 0, 0.1), 0 0.64rem 1.28rem rgba(0, 0, 0, 0.1), 0 1.28rem 2.4rem rgba(0, 0, 0, 0.03)',
    },
    hover: {
        backgroundColor: '#009148',
    },
    label: {
        color: 'white',
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: '1px',
        padding: `0 ${theme.spacing.m}`,
    },
}));


export interface IRegistrationForm {
    userData?: AccountContact;
    setSuccess?: () => void;
    fields: IField[];
    submitHandler: (values: any) => Promise<void>;
    signupText?: string;
}

export const RegistrationForm: React.FC<IRegistrationForm> = ({fields, setSuccess, userData, submitHandler, signupText = "Sign up"}) => {
    // export const AdditionalInfo: React.FC<IRegistrationForm> = (props) => {
    const [values, setValues] = useState(() =>
        fields.reduce((acc, field) => {
            acc[field.keyName] = '';
            return acc;
        }, {} as any),
    );

    const [validFields, setValidFields] = useState(() => {
        return fields.map((field) => !(field as any).required);
    });

    const allValid = useMemo(() => {
        return validFields.every((field) => field);
    }, [validFields]);

    const textFieldStyles = getTextFieldStyles();

    useLayoutEffect(() => {
        if (userData) {
            setValues((prevState: any) => {
                console.log('userData', userData);
                return {
                    ...prevState,
                    email: userData.email,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    companyName: userData.companyName,
                    jobTitle: userData.jobTitle,
                    country: userData.country,
                    phone: userData.phone,
                };
            });
        }
    }, [userData]);

    const [submitErrors, setSubmitErrors] = useState<Error | null>(null);

    const handleSubmit = useCallback(() => {
        const submitJob = submitHandler(values);
        if (setSuccess) {
            submitJob.then((success) => setSuccess()).catch((err) => setSubmitErrors(err));
        }
    }, [setSuccess, submitHandler, values]);

    const buttonClasses = getButtonStyles();

    return (
        <Stack horizontalAlign='center' tokens={{ childrenGap: 8 }} styles={{ root: { maxWidth: 550, width: '100%', margin: '0 auto' } }}>
            {fields.map((field, i) => {
                return (
                    <DynamicTextField
                        {...field as any}
                        key={field.keyName}
                        values={values}
                        setValues={setValues}
                        styles={textFieldStyles}
                        setValidField={(value = true) => setValidFields((fields) => replaceItemInArray(value, i, fields))}
                    />
                );
            })}
            <DefaultButton
                onClick={handleSubmit}
                text={signupText}
                disabled={!allValid}
                styles={{ root: buttonClasses.root, rootHovered: buttonClasses.hover, label: buttonClasses.label }}
            />
            {submitErrors && <Stack>Submit error: {submitErrors.message}</Stack>}
        </Stack>
    );
};



