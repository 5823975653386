import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { DefaultButton, Spinner, SpinnerSize, Stack, Text, useTheme } from '@fluentui/react';
import { useCallback, useLayoutEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { GetCustomer, UpsertCustomer } from '../../api/Services/Requester';
import { ContentSection, PageContent } from '../../Components/LayoutElements';
import { RegistrationForm } from '../../Components/Forms/RegistrationForm/RegistrationForm';
import { customerInfoFields } from '../../Components/Forms/customerInfoFields';
import { useButtonStyles } from '../../hooks/useButtonStyles';
import { Link } from 'react-router-dom';
import { AccountContact } from '../../api/generated/data-contracts';

export const UserProfile = () => {
    const theme = useTheme();
    const isAuth = useIsAuthenticated();
    const { instance } = useMsal();
    const [userData, setUserData] = useState<AccountContact | undefined>();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const buttonStyles = useButtonStyles();

    useLayoutEffect(() => {
        if (isAuth) {
            // const account = instance.getActiveAccount();
            GetCustomer().then(r => {
                console.log(r);
                setUserData(r);
            });
        }
    }, [instance, isAuth, setUserData]);

    const handleSubmit = useCallback((userData: AccountContact) => {
        setLoading(true);
        return UpsertCustomer(userData)
            .then(() => {
                setSuccess(true);
            })
            .catch(err => console.error(err))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (!isAuth) {
        return <Navigate to="/" />;
    }
    return (
        <Stack
            styles={{
                root: {
                    marginTop: 'var(--topbar_height)', // Cancel out styling from WordPress resources
                    'input[type=text], input[type=email], input[type=number]': {
                        color: theme.semanticColors.inputText,
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'unset',
                    },
                },
            }}
        >
            <PageContent>
                <ContentSection styles={{ minHeight: 'calc(100vh - var(--topbar_height))' }}>
                    <Stack horizontalAlign="center">
                        <Stack horizontalAlign="center" tokens={{ childrenGap: 10 }}>
                            <Text variant="xLarge">Edit user profile</Text>
                            {/* <Text variant='large'>Profile updated!</Text> */}
                            <Link to="/">
                                <DefaultButton styles={buttonStyles}>Go back</DefaultButton>
                            </Link>
                        </Stack>
                        <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { minHeight: '50vh' } }}>
                            {!success ? (
                                <>
                                    <RegistrationForm fields={customerInfoFields} userData={userData} submitHandler={handleSubmit} signupText="Update" />
                                    {loading && <Spinner size={SpinnerSize.large} />}
                                </>
                            ) : (
                                <Text variant="xxLarge">Profile updated!</Text>
                            )}
                        </Stack>
                    </Stack>
                </ContentSection>
            </PageContent>
        </Stack>
    );
};
