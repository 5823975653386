import { IStyle, makeStyles as makeStylesFluent, Theme } from '@fluentui/react';
import { useMemo } from 'react';

type TStyleFn = (theme: Theme, props: any) => {[key: string]: IStyle};

export const useGetStyles = (incomingStyles: TStyleFn | object, props?: any) => {   
    const wrappedCallback = useMemo(() => { 
        // If incomingStyles is not a function, make a function that returns the object
        const callback = typeof incomingStyles === 'function' ? incomingStyles : () => incomingStyles;
        // Make a higher order function so props is available in the callback function
        const propsWrapper = (props: any) => (theme: Theme) => callback(theme, props);
        // Initialize the props for the callback function
        return propsWrapper(props); 
    }, [props, incomingStyles])
    // Make the styles with the wrapped callback
    const getStyles = makeStylesFluent(wrappedCallback);
    // Return those beautiful styles
    return getStyles();
};

export const makeStylesWithProps = (fn: TStyleFn) => (theme: Theme, props?: any) => fn(theme, props)