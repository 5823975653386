import React from 'react';

interface IProps {
    maxWidth?: "none" | "medium" | "large";
    backgroundColor?: string;
    alignCenter?: boolean;
    grow?: number;
    noPadding?: boolean;
    noPaddingBottom?: boolean;
    noPaddingTop?: boolean;
    styles?: { [key: string]: any };
    // ref?: any;
}

export const ContentSection = React.forwardRef<HTMLInputElement, React.PropsWithChildren<IProps>>((props, ref) => {
    const styles = {
        ...props.styles,
        ...(props.grow && { flexGrow: props.grow }),
        // backgroundColor: "--var(--bg-color)"
        // ...(props.backgroundColor && { backgroundColor: props.backgroundColor })
    };
    return (
        <section ref={ref} style={styles} className={`content_section${(props.maxWidth === "medium" || !props.maxWidth) ? " side_spacing_medium" : ""}${props.maxWidth === "large" ? " side_spacing_large" : ""}${(props.noPadding || props.noPaddingTop) ? "" : " padding_top"}${(props.noPadding || props.noPaddingBottom) ? "" : " padding_bottom"}`}>
            <div className={`content_section_inner${props.alignCenter ? " align_center" : ""}`}>
                { props.children }
            </div>
        </section>
    );
});