import React from 'react';
import { IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, TextField } from '@fluentui/react';
import { stripLeadingZeroes } from "../helpers/stripLeadingZeroes";

interface IDynamicTextField {
    // setValidField: React.Dispatch<React.SetStateAction<boolean>>;
    validate?: {
        minLength?: number;
        maxLength?: number;
    };
    validateFn?: (val: string | number) => string;
    label: string;
    // setValues: React.Dispatch<any>,
    // setValues: React.Dispatch<React.SetStateAction<typeof defaultValues>>;
    setValues: React.Dispatch<React.SetStateAction<any>>;
    type?: string;
    keyName: string;
    values: { [key: string]: string; };
    autoFocus?: boolean;
    placeholder?: string;
    styles?: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
    disabled?: boolean;
    required?: boolean;
    multiline?: boolean;
    setValidField: (value?: boolean) => void;
}

export const DynamicTextField: React.FC<IDynamicTextField> = (props) => {
    // const { disabled, setValidField, required } = props;
    const { disabled, setValidField, required } = props;
    const validate = (newValue: string): string => {
        const value = newValue.trim();

        // Run validation function if we have one
        if (props?.validateFn) {
            const result = props.validateFn(value);
            if (result) {
                return result;
            }
        }

        if (!required) {
            setValidField(true);
            return '';
        }

        // If no max or min length is specified, skip validation
        if (!props?.validate?.maxLength || !props?.validate?.minLength) {
            props.setValidField(false);
            return '';
        }

        const length = value.length;
        if (length > props.validate.maxLength) {
            setValidField(false);
            return `${props.label} must have ${props.validate.maxLength} characters or less. Actual number is ${length}.`;
        } else if (length < props.validate.minLength) {
            setValidField(false);
            return `${props.label} must have ${props.validate.minLength} characters or more. Actual number is ${length}.`;
        }
        setValidField();
        // If we got here we have a valid value
        return '';
    };

    const onChange = (e: any, maybeSomeValue?: string | undefined) => {
        let newValue = maybeSomeValue ?? e.target.value;
        if (props.type === 'number') {
            newValue = stripLeadingZeroes(newValue);
        }
        props.setValues((state: any) => ({ ...state, [props.keyName]: newValue }));
    };

    return (
        <TextField
            label={props.label}
            placeholder={props?.placeholder}
            autoFocus={props.autoFocus}
            value={props.values[props.keyName] || ''}
            required={props.required}
            multiline={props.multiline}
            autoAdjustHeight={props.multiline}
            onChange={onChange}
            styles={props?.styles}
            onGetErrorMessage={validate}
            validateOnLoad={false}
            onBlur={onChange}
            autoComplete="off"
            disabled={disabled}
            type={props.type} />
    );
};
