import { ITheme } from "@fluentui/react";
// import { makeStyles } from '../../hooks/useGetStyles';


// import { makeStyles } from "@fluentui/react";

export const styles = (theme: ITheme, { isScrolled }: {isScrolled?: boolean}) => {
    return {
        root: [
            'topbar',
            {
                displayName: 'topbar',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: 'var(--topbar_height)',
                // backgroundColor: (theme as any).tokens.ghost.secondaryContentColor,
                // backgroundColor: theme.palette.blackTranslucent40,
                // backgroundColor: "rgba(255, 255, 255, 0.8)",
                // backgroundColor: '#323232',
                backgroundColor: isScrolled ? '#0763b7' : 'transparent',
                transition: "0.3s",
                // paddingRight: 90,
                // paddingLeft: 90,
                // backgroundColor: '#0763b7',
                // borderBottom: `1px solid ${theme.semanticColors.variantBorder}`,
                color: theme.palette.white,
                padding: `0px ${theme.spacing.l2}`,
                zIndex: 20,
                // boxShadow: theme.effects.elevation16,
            },
        ],
        icon: {
            color: theme.palette.white,
            height: 'var(--topbar_height)',
            fontSize: 20,
        },
        logoContainer: {
            // padding: '4px 0px',
            height: '100%',
            // width: 117,
            display: "flex",
            // marginLeft: 90,
            // justifyContent: "center"
            alignItems: "center"
        },
        logo: {
            height: '100%',
            objectFit: 'contain',
        },
        personaContainer: {
            width: 117,
            height: '100%',
        },
        persona: {
            paddingLeft: theme.spacing.s1,
        },
    };
};
