import { useState } from 'react';
import { useGetStyles } from '../../hooks/useGetStyles';
import { styles } from './Topbar.styles';
import { NavBarScrollable } from 'pum-spark';
import { Logo } from '../Logo/Logo';
import { useIsAuthenticated } from '@azure/msal-react';
import { useLocation } from 'react-router';
import { UserPersona } from '../UserPersona/UserPersona';
import { ActionButton, Callout, Stack, useTheme } from '@fluentui/react';
import { MsSignInButton } from '../MsSignInButton';

export const Topbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const { pathname } = useLocation();
    const classes = useGetStyles(styles, { isScrolled: pathname === '/userprofile' ? true : isScrolled });
    const isAuth = useIsAuthenticated();
    return (
        <NavBarScrollable
            onBelowThreshold={() => setIsScrolled(false)}
            onOverThreshold={() => setIsScrolled(true)}
            className={classes.root}
            style={{
                paddingRight: 90,
                paddingLeft: 105,
                backgroundColor: 'rgb(7, 99, 183)',
                // backgroundColor: "rgb(53, 120, 183, 0.44)",
            }}
            left={
                <div className={classes.logoContainer}>
                    <Logo
                        //
                        height="67px"
                    />
                </div>
            }
            middle={<span />}
            right={
                <>
                    {/* {links.map((link) => (
                        <LinkItem key={link.label} {...link} />
                    ))} */}
                    {isAuth ? <UserPersona /> : <LoginButton />}
                    {/* <LoginButton /> */}
                </>
            }
        />
    );
};

const LoginButton = () => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Stack>
            <ActionButton
                styles={{
                    root: {
                        //
                        textTransform: 'uppercase',
                        fontWeight: 900,
                        height: '100%',
                        paddingRight: theme.spacing.m,
                        paddingLeft: theme.spacing.m,
                    },
                    label: {
                        color: theme.palette.neutralLight,
                        fontSize: 16,
                        fontWeight: 400,
                        '.active &': {
                            fontWeight: 600,
                            // color: theme.palette.accent
                        },
                    },
                }}
                text="Login"
                id="topbar-login-button"
                onClick={() => setIsOpen(o => !o)}
            />
            {isOpen && (
                <Callout target={'#topbar-login-button'} onDismiss={() => setIsOpen(false)}>
                    <Stack tokens={{ padding: 10 }}>
                        <MsSignInButton setUserData={() => {}} />
                    </Stack>
                </Callout>
            )}
        </Stack>
    );
};
