import { useEffect, useState } from 'react';
import { DefaultButton, ITextProps, Modal, PartialTheme, Spinner, Stack, Text, ThemeProvider, mergeStyleSets } from '@fluentui/react';
import { SuiteManagerDeploymentButton } from '../Components/SuiteManagerDeploymentButton/SuiteManagerDeploymentButton';
import { MsSignInButton } from '../Components/MsSignInButton';
import { SignupForm } from '../Components/Forms/TrialForm/components/SignupForm';
import { useMsal } from '@azure/msal-react';
import { DeploymentModal } from '../Components/DeploymentModal/DeploymentModal';
import { apiCalls } from '../api/api';
import { useMutation } from '@tanstack/react-query';

export const ProductPage = () => {
    return (
        <div className={classes.container}>
            <img src={'/img/product-suite.png'} className={classes.image} alt="Product suite" />
            <ProductSuiteText />
        </div>
    );
};

const myTheme: PartialTheme = {
    palette: {
        themePrimary: '#0f8387',
        themeDark: '#324c4d',
    },
    defaultFontStyle: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 18,
    },
};

const ProductSuiteText = () => {
    const msalInstance = useMsal().instance;
    const [user, setUser] = useState(() => msalInstance.getActiveAccount());
    const [showTrialModal, setShowTrialModal] = useState(false);
    const [openDeployment, setOpenDeployment] = useState(false);
    // const [trialLoading, setTrialLoading] = useState(false);

    const {
        mutate: createTimeTrialEnv,
        isLoading: trialLoading,
        error,
    } = useMutation(() => {
        return apiCalls.trialTimeCreate().then(response => {
            console.log(response.data);
            if (response.data.trialUrl) {
                window.location.href = response.data.trialUrl;
            }
        });
    });

    return (
        <>
            <ThemeProvider style={{ backgroundColor: '#f2f2f2' }} theme={myTheme}>
                <Stack
                    styles={{
                        root: {
                            gap: 10,
                            marginLeft: 40,
                            marginRight: 40,
                            paddingRight: 100,
                            backgroundColor: '#f2f2f2',
                        },
                    }}
                >
                    <Stack
                        styles={{
                            root: {
                                paddingBottom: 20,
                            },
                        }}
                    >
                        <Header>Welcome to the Projectum Product Suite</Header>
                        <TextParagraf>
                            {/* Projectum Product Suite is a simple and easy-to-use installation wizard for our products. With few clicks you can install full
                            versions or demo versions. It also helps to get an overview of the products installed, if the products have newer versions available
                            and much more. */}
                            Projectum Product Suite is a simple and easy-to-use installation wizard for our products. With a few clicks, you can install full or
                            demo versions of products. The Projectum Product Suite provides an overview of the products installed, if newer versions are
                            available, and much more.
                        </TextParagraf>
                    </Stack>
                    <Stack horizontalAlign="start" tokens={{ childrenGap: 10 }}>
                        {user === null ? (
                            <>
                                {/* <TextParagraf>Please click “Sign in...” to continue and click accept on the next screen to continue</TextParagraf> */}
                                <TextParagraf>Please sign in, then click accept on the next screen to continue.</TextParagraf>
                                <MsSignInButton setUserData={setUser} />
                            </>
                        ) : (
                            <>
                                <SuiteManagerDeploymentButton open={() => setOpenDeployment(true)}>
                                    <ButtonText>{'GET Projectum Product Suite'.toUpperCase()}</ButtonText>
                                </SuiteManagerDeploymentButton>
                                <button
                                    style={{
                                        backgroundColor: '#00c160',
                                        border: 'none',
                                        outline: 'none',
                                        color: 'white',
                                        borderRadius: 25,
                                        padding: '13px 29px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => createTimeTrialEnv()}
                                >
                                    {/* If we are currently redirecting to Time trial, show that, otherwise show we are creating the environment - lastly the Get trial button. */}
                                    {trialLoading ? <Spinner label="Creating trial environment..." /> : <ButtonText>GET TIME FOR TEAMS TRIAL</ButtonText>}
                                </button>
                                {error && <Text style={{ color: 'red' }}>An error occurred while setting up the Time for Teams trial environment.</Text>}
                            </>
                        )}
                    </Stack>
                </Stack>
            </ThemeProvider>
            <Modal isOpen={showTrialModal}>
                <Stack tokens={{ padding: 20 }}>
                    <SignupForm />
                    <Stack horizontal horizontalAlign="end">
                        <DefaultButton onClick={() => setShowTrialModal(false)}>Close</DefaultButton>
                    </Stack>
                </Stack>
            </Modal>
            <DeploymentModal
                //
                show={openDeployment}
                close={() => setOpenDeployment(false)}
                title="Product Suite"
                area="ProductSuite"
                mode="Production"
                // debug
            />
        </>
    );
};

const Header = ({ children }: { children?: React.ReactNode }) => {
    return <Text styles={headerStyles}>{children}</Text>;
};

const headerStyles: ITextProps['styles'] = {
    root: {
        fontSize: 40,
        fontWeight: 500,
        marginTop: 24,
        marginBottom: 24,
    },
};

const TextParagraf = ({ children }: { children?: React.ReactNode }) => {
    return <Text styles={{ root: { lineHeight: 30 } }}>{children}</Text>;
};

const ButtonText = ({ children }: { children?: React.ReactNode }) => {
    return <Text styles={{ root: { color: 'white', fontWeight: 400, fontSize: 14, fontFamily: 'Montserrat', letterSpacing: '2px' } }}>{children}</Text>;
};

const classes = mergeStyleSets({
    container: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        alignItems: 'center',
        maxWidth: '1996.8px',
        margin: 'auto auto',
        '@media(max-width: 1024px)': {
            gridTemplateColumns: 'auto',
        },
    },
    image: {
        objectFit: 'contain',
        height: '100%',
        width: '100%',
    },
});
