import { emailRegex } from '../../helpers/regexes';



export const customerInfoFields = [
    {
        label: 'First Name',
        keyName: 'firstName',
        required: true,
        validate: {
            minLength: 2,
            maxLength: 50,
        },
    },
    {
        label: 'Last Name',
        keyName: 'lastName',
        required: true,
        validate: {
            minLength: 2,
            maxLength: 50,
        },
    },
    {
        label: 'Email',
        keyName: 'email',
        type: 'email',
        required: true,
        validate: {
            minLength: 3,
            maxLength: 1000,
        },
        validateFn: (newValue: string) => {
            const value = newValue.toLowerCase();
            const valid = emailRegex.test(value);
            if (!valid) {
                return `Not a valid email`;
            }
            return '';
        },
    },
    {
        label: 'Company Name',
        required: true,
        keyName: 'companyName',
        validate: {
            minLength: 2,
            maxLength: 50,
        },
    },
    {
        label: 'Job Title',
        keyName: 'jobTitle',
        required: true,
        validate: {
            minLength: 2,
            maxLength: 50,
        },
    },
    {
        label: 'Country',
        keyName: 'country',
        required: true,
        validate: {
            minLength: 2,
            maxLength: 50,
        },
    },
    {
        label: 'Phone',
        keyName: 'phone',
        required: false,
        type: 'number',
        validate: {
            minLength: 2,
            maxLength: 50,
        },
    },
];
