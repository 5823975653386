import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {
    //
    // PartialTheme,
    // ThemeProvider,
    initializeIcons,
} from '@fluentui/react';
// import { ThemeProvider } from '@fluentui/react';
import { ErrorBoundary } from './Components/ErrorBoundary';
import { SignupContext } from './contexts/SignupContext';
import { AuthProvider } from './contexts/AuthProvider';
import { LoadingSpinner } from './Components/Common/LoadingSpinner';
import { QueryProvider } from './contexts/QueryProvider';
import { Router } from './Router';
import './scss/index.scss';

initializeIcons();

// const myTheme: PartialTheme = {
//     palette: {
//         themePrimary: '#0f8387',
//         themeDark: '#324c4d',
//     },
//     // fonts: 'Roboto, sans-serif',
//     // fonts: 'Roboto, sans-serif',
//     // components:{
//     //     // h2: {
//     //     //     styles: {
//     //     //         fontSize: 40,
//     //     //         // fontFami
//     //     //         fontFamily: 'Roboto, sans-serif'
//     //     //     }
//     //     // }

//     // },
//     defaultFontStyle: {
//         fontFamily: 'Roboto, sans-serif',
//         fontSize: 18,
//         // lineHeight: 30
//     },
//     // components: {
//     //     Text: {
//     //         styles: {

//     //         }
//     //     }
//     // }
// };

const AppProvider = () => (
    <React.StrictMode>
        <ErrorBoundary>
            <QueryProvider>
                <Suspense fallback={<LoadingSpinner />}>
                    <AuthProvider>
                        {/* <ThemeProvider className="suitemanager" theme={myTheme}> */}
                        <BrowserRouter>
                            <SignupContext>
                                <Router />
                            </SignupContext>
                        </BrowserRouter>
                        {/* </ThemeProvider> */}
                    </AuthProvider>
                </Suspense>
            </QueryProvider>
        </ErrorBoundary>
    </React.StrictMode>
);

ReactDOM.render(<AppProvider />, document.getElementById('root'));
