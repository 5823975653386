import React, { useLayoutEffect, useMemo, useState } from 'react';
import { DefaultButton, Stack, Text } from '@fluentui/react';
import { useCallback } from 'react';
import { replaceItemInArray } from 'pum-spark';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { CreateTimeTrialDatabase, GetCustomer, UpsertCustomer } from '../../../../api/Services/Requester';
import { LoadingSpinner } from '../../../Common/LoadingSpinner';
import { getButtonStyles } from '../../RegistrationForm/RegistrationForm';
import { DynamicTextField } from "../../components/IDynamicTextField";
import { getTextFieldStyles } from "../../styles/getTextFieldStyles";
import { customerInfoFields } from "../../customerInfoFields";
import { AccountContact } from '../../../../api/generated/data-contracts';

// export const SignupForm: React.FC<IStepComponentProps> = () => {
export const SignupForm: React.FC = () => {
    const [values, setValues] = useState(() =>
        customerInfoFields.reduce((acc, field) => {
            acc[field.keyName] = '';
            return acc;
        }, {} as any),
    );

    const [userData, setUserData] = useState<AccountContact | undefined>();
    const [loading, setLoading] = useState(true);
    const [requestSent, setRequestSent] = useState(false);

    // const prevStepState = stepStates[currentStepIndex - 1];
    const [validFields, setValidFields] = useState(() => {
        return customerInfoFields.map((field) => !(field as any).required);
    });

    const allValid = useMemo(() => {
        return validFields.every((field) => field);
    }, [validFields]);

    const textFieldStyles = getTextFieldStyles();

    const isAuth = useIsAuthenticated();
    const { instance } = useMsal();

    // We want to set user data to show the MS sign in button
    // before we render to avoid jumps in the UI, thus layout effect
    useLayoutEffect(() => {
        if (isAuth) {
            // const account = instance.getActiveAccount();
            GetCustomer().then((r) => {
                console.log(r);
                setUserData(r);
            });
        }
    }, [instance, isAuth, setUserData]);

    useLayoutEffect(() => {
        if (userData) {
            setValues((prevState: any) => {
                console.log('userData', userData);
                return {
                    ...prevState,
                    email: userData.email,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    companyName: userData.companyName,
                    jobTitle: userData.jobTitle,
                    country: userData.country,
                    phone: userData.phone,
                };
            });
            setLoading(false);
        }
    }, [userData]);

    const handleSubmit = useCallback(() => {
        setLoading(true);
        setRequestSent(true);
        return UpsertCustomer(userData!).then((r) => {
            return CreateTimeTrialDatabase().then((rs) => {
                console.log(rs);
                if (rs.trialUrl) {
                    // window.location = rs.trialUrl;
                    window.location.href = rs.trialUrl;
                }
            });
        });
    }, [userData]);

    const buttonClasses = getButtonStyles();

    return (
        <Stack>
            {loading && (
                <LoadingSpinner>
                    {requestSent && (
                        <Text variant="large" styles={{ root: { backgroundColor: 'white' } }}>
                            This may take 1 to 2 minutes, hang on!
                        </Text>
                    )}
                </LoadingSpinner>
            )}
            <Stack>
                {customerInfoFields.map((field, i) => {
                    // const { Component, ...rest } = field;
                    return (
                        <DynamicTextField
                            //
                            {...(field as any)}
                            key={field.keyName}
                            values={values}
                            setValues={setValues}
                            styles={textFieldStyles}
                            setValidField={(value = true) => setValidFields((fields) => replaceItemInArray(value, i, fields))}
                        />
                    );
                })}
            </Stack>

            <DefaultButton
                onClick={handleSubmit}
                text="Start trial now!"
                disabled={!allValid}
                styles={{ root: buttonClasses.root, rootHovered: buttonClasses.hover, label: buttonClasses.label }}
            />
        </Stack>
    );
};
