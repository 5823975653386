import * as React from "react";
// import { makeStyles } from "@fluentui/react-theme-provider";
// import { useGetStyles, makeStyles } from '../../hooks/useGetStyles';

interface IProps {
    className?: string;
    innerClassName?: string;
};

export const PageContent: React.FC<IProps> = (props) => {

    return (
        <div className={`page_content${props.className ? ` ${props.className}` : ""}`}>
            <div className={`page_content_inner${props.innerClassName ? ` ${props.innerClassName}` : ""}`}>
                    { props.children }
            </div>
            {/* <div className="flex_content">
                <div className={`page_content_inner${props.noSideSpacing ? "" : ""}`} data-is-scrollable>
                        { props.children }
                </div>
            </div> */}
        </div>
    )
};
