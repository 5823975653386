import { DefaultButton, Stack, Text } from '@fluentui/react';
import { Route, NavLink, Routes } from 'react-router-dom';
import { ErrorUiProps, SafeSuspense } from './Components/ErrorBoundary';
import { LoadingSpinner } from './Components/Common/LoadingSpinner';
import { NotFound, ProductPage } from './pages';
import { UserProfile } from './pages/UserProfile/UserProfile';
import { App } from './App';

// export const routes = [
//     {
//         path: '/',
//         component: ProductPage,
//         exact: true,
//         menu: false,
//     },
//     {
//         path: '/:product/:signup',
//         component: ProductPage,
//         exact: false,
//     },
//     {
//         path: '/:product',
//         component: ProductPage,
//         exact: false,
//     },
// ];

const ErrorComponent = ({ resetError }: ErrorUiProps) => {
    return (
        <Stack styles={{ root: { height: '100vh', width: '100vw' } }} horizontalAlign="center" verticalAlign="center">
            <Stack styles={{ root: { height: 400, width: 400 } }} horizontalAlign="center">
                <Text as="p" variant="xLarge">
                    Something unexpected happened.
                </Text>
                <span>
                    <DefaultButton onClick={resetError}>Lets try again</DefaultButton>
                </span>
                <Text as="p">Try a different place if the error keeps happening</Text>
                <NavLink to="/">
                    <DefaultButton onClick={resetError}>Go to home</DefaultButton>
                </NavLink>
            </Stack>
        </Stack>
    );
};

export const Router = () => {
    return (
        <SafeSuspense errorComponent={ErrorComponent} fallback={<LoadingSpinner />}>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route index element={<ProductPage />} />
                    <Route path={'iframe'} element={<ProductPage />} />
                    <Route path="/userprofile" element={<UserProfile />} />
                    {/* <Route path="/:product/:signup" element={<ProductPage />} />
                    <Route path="/:product" element={<ProductPage />} /> */}
                    <Route path=":notfound/*" element={<NotFound />} />
                </Route>
            </Routes>
        </SafeSuspense>
    );
};
