import React from "react";
import { useTheme } from '@fluentui/react';
import { Text } from '@fluentui/react';

interface IProps {
    title: string;
    subText?: string;
    align?: "left" | "center" | "right";
    noPaddingBottom?: boolean;
}

export const PageTitle: React.FC<IProps> = (props) => {
    const theme = useTheme();

    return (
        <>
            <Text 
                variant={"superLarge"} 
                as="h2" 
                styles={{ 
                    root: { 
                        display: "inline-block",
                        width: "100%",
                        // maxWidth: 600,
                        fontWeight: 600,
                        paddingBottom: (props.noPaddingBottom || props.subText) ? 0 : theme.spacing.l2, 
                        margin: "0 auto", 
                        // opacity: 0.9,
                        textAlign: props.align ? props.align : "center",
                        // color: theme.palette.themeSecondary,
                    }
                }}
            >
                { props.title }
            </Text>
            <Text 
                variant={"mediumPlus"} 
                as="h4" 
                styles={{ 
                    root: { 
                        paddingTop: theme.spacing.s2, 
                        paddingBottom: props.noPaddingBottom ? 0 : theme.spacing.l2, 
                        margin: "0 auto", 
                        display: "inline-block",
                        width: "100%",
                        maxWidth: 600,
                        textAlign: props.align ? props.align : "center",
                        // opacity: 0.9,
                        color: theme.semanticColors.bodySubtext
                    }
                }}
            >
                { props.subText }
            </Text>
        </>
    )
};

