import React from "react";
import { Spinner, SpinnerSize } from '@fluentui/react';
import { mergeStyles, AnimationClassNames } from '@fluentui/react';

const style = mergeStyles({ 
    zIndex: 9000, 
    position: "absolute", 
    top: 0, 
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255,255,255,0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200,
});

interface IProps {
    styles?: any
    size?: SpinnerSize
}

export const LoadingSpinner: React.FC<IProps> = ({children, styles, size}) => {

    return (
        <div className={`${style} ${AnimationClassNames.fadeIn100}`}>
            <Spinner size={size || SpinnerSize.large} styles={styles} />
            {children}
        </div>
    )
};

