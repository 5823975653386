import { Outlet, useLocation } from 'react-router-dom';
import { Topbar } from './Components/Topbar/Topbar';

export const App = () => {
    const { pathname } = useLocation();
    if (pathname === '/iframe') {
        return <Outlet />;
    }
    return (
        <>
            <Topbar />
            <div style={{ paddingTop: 100 }}>
                <Outlet />
            </div>
        </>
    );
};
