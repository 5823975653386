import React, { useCallback } from 'react';
import { DefaultButton, IButtonProps } from '@fluentui/react';
import { MicrosoftLogo } from './Icons/MicrosoftLogo';
import { makeStylesWithProps, useGetStyles } from '../hooks';
import { useMsal } from '@azure/msal-react';
import { AccountInfo, PopupRequest } from '@azure/msal-browser';

type TButtonVariant = 'short' | 'long';

interface ISignInProps {
    setUserData: React.Dispatch<React.SetStateAction<AccountInfo | null>>;
    variant?: TButtonVariant;
}

export const MsSignInButton: React.FC<ISignInProps & IButtonProps> = props => {
    const { setUserData, variant, ...buttonProps } = props;

    const msal = useMsal();
    const msalInstance = msal.instance;

    const setFirstAccountActive = useCallback(() => {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) return;

        msalInstance.setActiveAccount(accounts[0]);
        const account = accounts[0];
        props.setUserData(account);
    }, [msalInstance, props]);

    const authenticate = useCallback(async () => {
        const accounts = msalInstance.getAllAccounts();

        if (accounts.length > 0) {
            // Authenticated already
            setFirstAccountActive();
        } else {
            try {
                //const authResult = await msalInstance.loginPopup({ scopes: [msalInstance.getConfiguration().auth.clientId + '/.default'], prompt: 'consent' } as PopupRequest);
                const authResult = await msalInstance.loginPopup({ scopes: [msalInstance.getConfiguration().auth.clientId + '/.default'] } as PopupRequest);
                console.log(authResult);
            } catch (error) {
                console.log('Sign-in was cancelled or the user has insufficient rights to consent.');
            } finally {
                setFirstAccountActive();
            }
        }
    }, [msalInstance, setFirstAccountActive]);

    return <SignInWithMicrosoftButton {...buttonProps} onClick={authenticate} variant={variant} />;
};

interface ISignInWithMicrosoftButton {
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    variant?: TButtonVariant;
}

const SignInWithMicrosoftButton: React.FC<ISignInWithMicrosoftButton & IButtonProps> = ({ children, variant, ...buttonProps }) => {
    const styles = useGetStyles(getStyles, variant);

    return (
        <DefaultButton styles={{ root: styles.root, label: styles.label }} {...buttonProps}>
            <MicrosoftLogo className={styles.logo} />
            {variant === 'short' ? 'Sign in' : 'Sign in with Microsoft'}
            {children}
        </DefaultButton>
    );
};

const getStyles = makeStylesWithProps((theme, variant) => ({
    logo: {
        height: 41 - 12 - 12,
        marginRight: 12,
    },
    root: {
        height: 41,
        ...(variant !== 'short' && {
            minWidth: 180,
        }),
        backgroundColor: '#fff',
        color: '#5e5e5e',
        border: '2px solid #8c8c8c',
        whiteSpace: 'pre',
        padding: 12,
        fontWeight: 600,
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    },
    label: {
        color: 'white',
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: '1px',
    },
}));
