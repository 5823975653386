import { PublicClientApplication } from '@azure/msal-browser';
// import { loginRequest } from "../authConfig";
import { queryClient } from '../contexts/QueryProvider';
import { Api } from './generated/Api';

/**
 * This instance contains methods to make all calls to the projectum-suitemanager-deployment backend.
 */
export const apiCalls = new Api({ customFetch });

async function customFetch(input: RequestInfo | URL, init?: RequestInit) {
    if (input === '/api/Auth/options') {
        return fetch(input, init);
    }

    const msalInstance = queryClient.getQueryData<PublicClientApplication>(['MSAL']);
    const account = msalInstance!.getActiveAccount();
    if (!account) {
        throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }
    const loginRequest = {
        // ...loginRequest,
        scopes: [msalInstance!.getConfiguration().auth.clientId + '/.default'],
        account: account,
    };
    const response = await msalInstance!.acquireTokenSilent(loginRequest).catch(async (error: any) => {
        return await msalInstance!.acquireTokenPopup(loginRequest);
    });

    const headers = {
        Authorization: `Bearer ${response.accessToken}`,
        Accept: 'application/json',
    };

    return fetch(input as any, { ...init, headers: { ...init?.headers, ...headers } });
}
