/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    AccountContact,
    AppRegistration,
    AppRegistrationConsent,
    AuthOptions,
    CreatedTrial,
    DeploymentData,
    DeploymentStartedResponse,
    InstallationsSchemaListParams,
    Location,
    NewAppRegistration,
    NewResourceGroup,
    ResourceGroup,
    ResourceValidationRequest,
    ResourceValidationResponse,
    Schema,
    Subscription,
    Workspace,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthOptionsList
     * @request GET:/api/Auth/options
     * @secure
     * @response `200` `AuthOptions` Success
     */
    authOptionsList = (params: RequestParams = {}) =>
        this.request<AuthOptions, any>({
            path: `/api/Auth/options`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Customer
     * @name CustomerList
     * @request GET:/api/Customer
     * @secure
     * @response `200` `AccountContact` Success
     */
    customerList = (params: RequestParams = {}) =>
        this.request<AccountContact, any>({
            path: `/api/Customer`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Customer
     * @name CustomerCreate
     * @request POST:/api/Customer
     * @secure
     * @response `200` `void` Success
     */
    customerCreate = (data: AccountContact, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/Customer`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Installations
     * @name InstallationsValidateResourceCreate
     * @request POST:/api/Installations/validateResource
     * @secure
     * @response `200` `ResourceValidationResponse` Success
     */
    installationsValidateResourceCreate = (data: ResourceValidationRequest, params: RequestParams = {}) =>
        this.request<ResourceValidationResponse, any>({
            path: `/api/Installations/validateResource`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Installations
     * @name InstallationsSubscriptionsList
     * @request GET:/api/Installations/subscriptions
     * @secure
     * @response `200` `(Subscription)[]` Success
     */
    installationsSubscriptionsList = (params: RequestParams = {}) =>
        this.request<Subscription[], any>({
            path: `/api/Installations/subscriptions`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Installations
     * @name InstallationsSubscriptionsResourcegroupsDetail
     * @request GET:/api/Installations/subscriptions/{subscriptionId}/resourcegroups
     * @secure
     * @response `200` `(ResourceGroup)[]` Success
     */
    installationsSubscriptionsResourcegroupsDetail = (subscriptionId: string, params: RequestParams = {}) =>
        this.request<ResourceGroup[], any>({
            path: `/api/Installations/subscriptions/${subscriptionId}/resourcegroups`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Installations
     * @name InstallationsSubscriptionsResourcegroupsCreate
     * @request POST:/api/Installations/subscriptions/{subscriptionId}/resourcegroups
     * @secure
     * @response `200` `ResourceGroup` Success
     */
    installationsSubscriptionsResourcegroupsCreate = (subscriptionId: string, data: NewResourceGroup, params: RequestParams = {}) =>
        this.request<ResourceGroup, any>({
            path: `/api/Installations/subscriptions/${subscriptionId}/resourcegroups`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Installations
     * @name InstallationsSubscriptionsResourcegroupsOwnerDetail
     * @request GET:/api/Installations/subscriptions/{subscriptionId}/resourcegroups/{resourceGroupName}/owner
     * @secure
     * @response `200` `void` Success
     */
    installationsSubscriptionsResourcegroupsOwnerDetail = (subscriptionId: string, resourceGroupName: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/Installations/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}/owner`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Installations
     * @name InstallationsSubscriptionsLocationsDetail
     * @request GET:/api/Installations/subscriptions/{subscriptionId}/locations
     * @secure
     * @response `200` `(Location)[]` Success
     */
    installationsSubscriptionsLocationsDetail = (subscriptionId: string, params: RequestParams = {}) =>
        this.request<Location[], any>({
            path: `/api/Installations/subscriptions/${subscriptionId}/locations`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Installations
     * @name InstallationsAppregistrationCreate
     * @request POST:/api/Installations/appregistration
     * @secure
     * @response `200` `AppRegistration` Success
     */
    installationsAppregistrationCreate = (data: NewAppRegistration, params: RequestParams = {}) =>
        this.request<AppRegistration, any>({
            path: `/api/Installations/appregistration`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Installations
     * @name InstallationsAppregistrationDetail
     * @request GET:/api/Installations/appregistration/{clientId}
     * @secure
     * @response `200` `AppRegistrationConsent` Success
     */
    installationsAppregistrationDetail = (clientId: string, params: RequestParams = {}) =>
        this.request<AppRegistrationConsent, any>({
            path: `/api/Installations/appregistration/${clientId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Installations
     * @name InstallationsSchemaList
     * @request GET:/api/Installations/schema
     * @secure
     * @response `200` `Schema` Success
     */
    installationsSchemaList = (query: InstallationsSchemaListParams, params: RequestParams = {}) =>
        this.request<Schema, any>({
            path: `/api/Installations/schema`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Installations
     * @name InstallationsSubscriptionsDetail
     * @request GET:/api/Installations/subscriptions/{subscriptionId}
     * @secure
     * @response `200` `(Workspace)[]` Success
     */
    installationsSubscriptionsDetail = (subscriptionId: string, params: RequestParams = {}) =>
        this.request<Workspace[], any>({
            path: `/api/Installations/subscriptions/${subscriptionId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Installations
     * @name InstallationsSubscriptionsWorkspacesCreate
     * @request POST:/api/Installations/subscriptions/{subscriptionId}/workspaces/{workspaceName}
     * @secure
     * @response `200` `Workspace` Success
     */
    installationsSubscriptionsWorkspacesCreate = (subscriptionId: string, workspaceName: string, data: ResourceGroup, params: RequestParams = {}) =>
        this.request<Workspace, any>({
            path: `/api/Installations/subscriptions/${subscriptionId}/workspaces/${workspaceName}`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Installations
     * @name InstallationsDeployCreate
     * @request POST:/api/Installations/deploy
     * @secure
     * @response `200` `DeploymentStartedResponse` Success
     */
    installationsDeployCreate = (data: DeploymentData, params: RequestParams = {}) =>
        this.request<DeploymentStartedResponse, any>({
            path: `/api/Installations/deploy`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Message
     * @name MessageCreate
     * @request POST:/api/Message
     * @secure
     * @response `200` `void` Success
     */
    messageCreate = (data: DeploymentData, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/Message`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Trial
     * @name TrialTimeCreate
     * @request POST:/api/Trial/time
     * @secure
     * @response `200` `CreatedTrial` Success
     */
    trialTimeCreate = (params: RequestParams = {}) =>
        this.request<CreatedTrial, any>({
            path: `/api/Trial/time`,
            method: 'POST',
            secure: true,
            format: 'json',
            ...params,
        });
}
