export function Logo(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg version="1.1" viewBox="0 0 324.99 96.01" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="Artwork">
                <path
                    fill="#fff"
                    d="m35 34.57c0 6.05-1.49 10.87-4.47 14.46s-7.01 5.38-12.1 5.38c-4.36 0-7.75-1.81-10.16-5.42h-0.14v21.1h-8.13v-52.57h8.12v5.77h0.14c2.86-4.43 6.85-6.65 11.96-6.65 4.6 0 8.21 1.61 10.83 4.82 2.63 3.21 3.94 7.59 3.94 13.12zm-8.44-0.14c0-3.47-0.79-6.2-2.37-8.18s-3.79-2.97-6.63-2.97-5.13 1-6.88 2.99-2.62 4.57-2.62 7.74v4.61c0 2.65 0.83 4.84 2.5 6.56 1.66 1.72 3.74 2.58 6.23 2.58 3.1 0 5.5-1.15 7.21-3.45s2.57-5.59 2.57-9.88z"
                />
                <path
                    fill="#fff"
                    d="m64.19,25.43c-.42-.3-1.11-.59-2.08-.84-.96-.26-1.84-.39-2.64-.39-2.37,0-4.28,1.01-5.73,3.02-1.45,2.02-2.18,4.64-2.18,7.88v18.43h-8.12V17.52h8.12v7h.14c.8-2.41,2-4.29,3.62-5.63,1.62-1.34,3.48-2,5.59-2,1.41,0,2.5.16,3.27.49v8.05Z"
                />
                <path
                    fill="#fff"
                    d="m103.16 35.17c0 5.84-1.69 10.5-5.06 14-3.38 3.49-7.96 5.24-13.75 5.24s-9.98-1.66-13.28-4.98c-3.29-3.32-4.94-7.81-4.94-13.49 0-5.98 1.69-10.69 5.06-14.14 3.38-3.45 8.05-5.17 14.03-5.17 5.63 0 10.02 1.65 13.19 4.94s4.75 7.83 4.75 13.59zm-8.44 0.28c0-3.99-0.89-7.01-2.66-9.07s-4.2-3.09-7.3-3.09-5.69 1.08-7.49 3.24c-1.81 2.16-2.71 5.21-2.71 9.14s0.9 6.79 2.71 8.92c1.81 2.12 4.33 3.18 7.56 3.18s5.72-1.04 7.39-3.13c1.66-2.09 2.5-5.15 2.5-9.18z"
                />
                <path
                    fill="#fff"
                    d="m119.72 52.16c0 5.79-1.27 10.29-3.82 13.49-2.54 3.2-6.1 4.8-10.67 4.8-0.73 0-1.56-0.1-2.5-0.3s-1.74-0.43-2.39-0.69v-7.07c1.67 0.96 3.18 1.44 4.54 1.44 2.16 0 3.82-0.93 4.98-2.78s1.74-4.48 1.74-7.88v-35.65h8.12v34.64zm1.02-47.38c0 1.34-0.49 2.45-1.46 3.34s-2.15 1.34-3.53 1.34-2.56-0.45-3.52-1.34-1.44-2-1.44-3.34 0.49-2.5 1.46-3.41 2.14-1.37 3.5-1.37c1.43 0 2.62 0.47 3.57 1.41s1.42 2.06 1.42 3.38z"
                />
                <path
                    fill="#fff"
                    d="m136.43 37.91c0.16 3.28 1.16 5.78 2.99 7.49s4.44 2.57 7.84 2.57c2.04 0 4-0.32 5.87-0.97 1.88-0.64 3.52-1.47 4.92-2.48v6.86c-1.36 0.89-3.19 1.62-5.49 2.18s-4.72 0.84-7.28 0.84c-5.46 0-9.7-1.64-12.7-4.91s-4.5-7.81-4.5-13.63c0-3.85 0.74-7.21 2.23-10.09s3.55-5.13 6.19-6.74 5.54-2.41 8.7-2.41c4.97 0 8.83 1.52 11.59 4.57 2.75 3.05 4.13 7.27 4.13 12.66v4.04h-24.51zm16.42-6.12c0-2.86-0.67-5.04-2-6.54-1.34-1.5-3.21-2.25-5.63-2.25-2.2 0-4.12 0.81-5.73 2.44-1.62 1.63-2.64 3.75-3.06 6.35h16.42z"
                />
                <path
                    fill="#fff"
                    d="m194.14 52.02c-2.77 1.59-6.18 2.39-10.23 2.39-3.47 0-6.56-0.75-9.27-2.25s-4.82-3.63-6.33-6.4-2.27-5.86-2.27-9.29c0-3.92 0.78-7.37 2.34-10.38 1.56-3 3.82-5.33 6.79-6.98s6.41-2.48 10.32-2.48c1.52 0 3.11 0.17 4.75 0.51s2.94 0.78 3.9 1.32v7.7c-2.65-1.92-5.42-2.88-8.3-2.88-3.38 0-6.11 1.13-8.21 3.39s-3.15 5.28-3.15 9.06 1.01 6.69 3.02 8.83c2.02 2.13 4.75 3.2 8.19 3.2 1.24 0 2.64-0.27 4.19-0.81s2.97-1.28 4.26-2.22v7.28z"
                />
                <path
                    fill="#fff"
                    d="m221.58,53.18c-.49.28-1.37.56-2.62.83-1.25.27-2.47.4-3.64.4-7.06,0-10.59-3.8-10.59-11.4v-18.96h-5.98v-6.54h5.98v-8.3l8.16-2.5v10.8h8.69v6.54h-8.69v17.45c0,2.27.41,3.89,1.23,4.84.82.95,2.11,1.42,3.87,1.42.47,0,1.06-.1,1.78-.3.71-.2,1.32-.46,1.81-.79v6.51Z"
                />
                <path
                    fill="#fff"
                    d="m252.07 53.53v-5.42h-0.14c-1.1 1.95-2.6 3.48-4.48 4.61-1.89 1.13-4.02 1.69-6.38 1.69-4.2 0-7.4-1.25-9.6-3.76s-3.31-6.27-3.31-11.29v-21.84h8.23v20.82c0 6.28 2.5 9.43 7.49 9.43 2.46 0 4.44-0.87 5.94-2.6s2.25-4.01 2.25-6.82v-20.82h8.19v36.02h-8.19z"
                />
                <path
                    fill="#fff"
                    d="m316.82 53.53v-20.33c0-3.47-0.54-5.99-1.62-7.56s-2.92-2.36-5.52-2.36c-2.13 0-3.93 0.97-5.38 2.92s-2.18 4.31-2.18 7.1v20.22h-8.12v-21.03c0-6.14-2.36-9.21-7.07-9.21-2.25 0-4.07 0.93-5.47 2.8-1.4 1.86-2.09 4.27-2.09 7.23v20.22h-8.12v-36.01h8.12v5.56h0.14c2.56-4.29 6.35-6.44 11.4-6.44 2.41 0 4.53 0.62 6.35 1.86s3.11 2.98 3.89 5.21c1.45-2.46 3.17-4.26 5.15-5.38 1.98-1.13 4.3-1.69 6.95-1.69 3.82 0 6.74 1.16 8.74 3.48s3.01 6.04 3.01 11.15v22.26h-8.16z"
                />
                <path fill="#fff" d="m165.39 93.19v-7.97h-2.85v-1.62h7.62v1.62h-2.84v7.97h-1.94z" />
                <path
                    fill="#fff"
                    d="m173.12,83.6v3.53c.59-.69,1.3-1.04,2.13-1.04.42,0,.8.08,1.14.24.34.16.6.36.77.6.17.24.29.51.35.81.06.3.09.76.09,1.38v4.08h-1.84v-3.67c0-.73-.04-1.19-.1-1.39s-.19-.35-.37-.47c-.18-.12-.4-.17-.66-.17-.3,0-.58.07-.82.22-.24.15-.42.37-.53.67-.11.3-.17.74-.17,1.32v3.48h-1.84v-9.59h1.84Z"
                />
                <path
                    fill="#fff"
                    d="m183.5,90.98l1.83.31c-.24.67-.61,1.18-1.12,1.53s-1.14.53-1.91.53c-1.21,0-2.1-.39-2.68-1.18-.46-.63-.69-1.43-.69-2.39,0-1.15.3-2.05.9-2.7s1.36-.98,2.28-.98c1.03,0,1.85.34,2.45,1.02.6.68.88,1.73.86,3.14h-4.61c.01.55.16.97.45,1.27s.64.45,1.06.45c.29,0,.53-.08.73-.24.2-.16.34-.41.45-.76Zm.1-1.86c-.01-.53-.15-.94-.41-1.21-.26-.28-.58-.42-.95-.42-.4,0-.73.15-.99.44-.26.29-.39.69-.39,1.19h2.75Z"
                />
                <path
                    fill="#fff"
                    d="m190.66,83.6h3.54c.8,0,1.41.06,1.82.18.56.17,1.04.46,1.45.88.4.42.71.94.92,1.55.21.61.31,1.37.31,2.27,0,.79-.1,1.47-.29,2.04-.24.7-.58,1.26-1.03,1.69-.34.33-.79.58-1.36.77-.43.14-1,.2-1.71.2h-3.64v-9.59Zm1.94,1.62v6.35h1.45c.54,0,.93-.03,1.17-.09.31-.08.57-.21.78-.4s.38-.5.51-.93c.13-.43.2-1.01.2-1.76s-.07-1.31-.2-1.71c-.13-.4-.31-.71-.55-.93-.24-.22-.53-.37-.9-.45-.27-.06-.8-.09-1.59-.09h-.87Z"
                />
                <path fill="#fff" d="m200.32 85.3v-1.7h1.84v1.7h-1.84zm0 7.89v-6.95h1.84v6.95h-1.84z" />
                <path
                    fill="#fff"
                    d="m203.85,93.65l2.1.26c.03.24.12.41.24.5.17.13.45.2.82.2.48,0,.84-.07,1.08-.22.16-.1.28-.25.37-.46.06-.15.08-.43.08-.84v-1.01c-.55.75-1.24,1.12-2.08,1.12-.93,0-1.67-.39-2.22-1.18-.43-.62-.64-1.4-.64-2.33,0-1.16.28-2.05.84-2.67s1.26-.92,2.09-.92,1.57.38,2.13,1.13v-.97h1.72v6.23c0,.82-.07,1.43-.2,1.84s-.32.72-.57.96c-.24.23-.57.41-.98.54s-.92.2-1.55.2c-1.18,0-2.01-.2-2.51-.61s-.74-.91-.74-1.53c0-.06,0-.14,0-.22Zm1.64-4.08c0,.74.14,1.28.43,1.62s.64.51,1.06.51c.45,0,.83-.18,1.14-.53.31-.35.46-.87.46-1.56s-.15-1.25-.44-1.6c-.3-.35-.67-.52-1.13-.52s-.8.17-1.09.51-.43.86-.43,1.57Z"
                />
                <path fill="#fff" d="m212.15 85.3v-1.7h1.84v1.7h-1.84zm0 7.89v-6.95h1.84v6.95h-1.84z" />
                <path
                    fill="#fff"
                    d="m219.11,86.24v1.47h-1.26v2.8c0,.57.01.9.04.99.02.09.08.17.16.23.08.06.19.09.31.09.17,0,.42-.06.74-.18l.16,1.43c-.43.18-.91.27-1.45.27-.33,0-.63-.06-.9-.17-.27-.11-.46-.26-.59-.43-.12-.18-.21-.42-.26-.72-.04-.21-.06-.65-.06-1.3v-3.03h-.84v-1.47h.84v-1.38l1.84-1.07v2.45h1.26Z"
                />
                <path
                    fill="#fff"
                    d="m221.84,88.36l-1.67-.3c.19-.67.51-1.17.97-1.49s1.14-.48,2.04-.48c.82,0,1.43.1,1.83.29s.68.44.85.74c.16.3.25.85.25,1.65l-.02,2.15c0,.61.03,1.06.09,1.35.06.29.17.6.33.93h-1.82c-.05-.12-.11-.3-.18-.54-.03-.11-.05-.18-.07-.22-.31.31-.65.53-1.01.69-.36.15-.74.23-1.14.23-.72,0-1.28-.19-1.69-.58-.41-.39-.62-.88-.62-1.47,0-.39.09-.74.28-1.05.19-.31.45-.54.79-.71.34-.16.83-.31,1.46-.43.86-.16,1.45-.31,1.79-.45v-.18c0-.35-.09-.61-.26-.76-.17-.15-.5-.23-.99-.23-.33,0-.58.06-.76.19s-.33.35-.44.68Zm2.46,1.49c-.24.08-.61.17-1.12.28s-.84.22-1,.32c-.24.17-.36.39-.36.65s.1.48.29.67.44.28.73.28c.33,0,.65-.11.95-.33.22-.17.37-.37.44-.61.05-.16.07-.46.07-.9v-.37Z"
                />
                <path fill="#fff" d="m227.87 93.19v-9.59h1.84v9.59h-1.84z" />
                <path fill="#fff" d="m235.35 93.19v-9.59h1.88l3.92 6.4v-6.4h1.8v9.59h-1.94l-3.87-6.25v6.25h-1.8z" />
                <path
                    fill="#fff"
                    d="m249.04,90.98l1.83.31c-.24.67-.61,1.18-1.12,1.53s-1.14.53-1.91.53c-1.21,0-2.1-.39-2.68-1.18-.46-.63-.69-1.43-.69-2.39,0-1.15.3-2.05.9-2.7s1.36-.98,2.28-.98c1.03,0,1.85.34,2.45,1.02.6.68.88,1.73.86,3.14h-4.61c.01.55.16.97.45,1.27s.64.45,1.06.45c.29,0,.53-.08.73-.24.2-.16.34-.41.45-.76Zm.1-1.86c-.01-.53-.15-.94-.41-1.21-.26-.28-.58-.42-.95-.42-.4,0-.73.15-.99.44-.26.29-.39.69-.39,1.19h2.75Z"
                />
                <path fill="#fff" d="m252.42 85.3v-1.7h1.84v1.7h-1.84zm0 7.89v-6.95h1.84v6.95h-1.84z" />
                <path
                    fill="#fff"
                    d="m255.95,93.65l2.1.26c.03.24.12.41.24.5.17.13.45.2.82.2.48,0,.84-.07,1.08-.22.16-.1.28-.25.37-.46.06-.15.08-.43.08-.84v-1.01c-.55.75-1.24,1.12-2.08,1.12-.93,0-1.67-.39-2.22-1.18-.43-.62-.64-1.4-.64-2.33,0-1.16.28-2.05.84-2.67s1.26-.92,2.09-.92,1.57.38,2.13,1.13v-.97h1.72v6.23c0,.82-.07,1.43-.2,1.84s-.32.72-.57.96c-.24.23-.57.41-.98.54s-.92.2-1.55.2c-1.18,0-2.01-.2-2.51-.61s-.74-.91-.74-1.53c0-.06,0-.14,0-.22Zm1.64-4.08c0,.74.14,1.28.43,1.62s.64.51,1.06.51c.45,0,.83-.18,1.14-.53.31-.35.46-.87.46-1.56s-.15-1.25-.44-1.6c-.3-.35-.67-.52-1.13-.52s-.8.17-1.09.51-.43.86-.43,1.57Z"
                />
                <path
                    fill="#fff"
                    d="m266.09,83.6v3.53c.59-.69,1.3-1.04,2.13-1.04.42,0,.8.08,1.14.24.34.16.6.36.77.6.17.24.29.51.35.81.06.3.09.76.09,1.38v4.08h-1.84v-3.67c0-.73-.04-1.19-.1-1.39s-.19-.35-.37-.47c-.18-.12-.4-.17-.66-.17-.3,0-.58.07-.82.22-.24.15-.42.37-.53.67-.11.3-.17.74-.17,1.32v3.48h-1.84v-9.59h1.84Z"
                />
                <path
                    fill="#fff"
                    d="m272.33,93.19v-9.59h1.84v3.45c.57-.65,1.24-.97,2.01-.97.85,0,1.55.31,2.1.92.55.61.83,1.49.83,2.64s-.28,2.1-.85,2.74c-.56.64-1.25.96-2.06.96-.4,0-.79-.1-1.17-.3-.39-.2-.72-.49-1-.88v1.02h-1.71Zm1.82-3.62c0,.72.11,1.25.34,1.6.32.49.74.73,1.27.73.41,0,.75-.17,1.04-.52.29-.35.43-.89.43-1.64,0-.79-.14-1.37-.43-1.72-.29-.35-.66-.53-1.11-.53s-.81.17-1.1.51-.44.86-.44,1.56Z"
                />
                <path
                    fill="#fff"
                    d="m280.17,89.62c0-.61.15-1.2.45-1.77.3-.57.73-1.01,1.28-1.31s1.17-.45,1.85-.45c1.05,0,1.91.34,2.58,1.02.67.68,1.01,1.54,1.01,2.59s-.34,1.92-1.02,2.61c-.68.69-1.53,1.04-2.56,1.04-.64,0-1.24-.14-1.82-.43-.58-.29-1.02-.71-1.32-1.27s-.45-1.23-.45-2.03Zm1.88.1c0,.69.16,1.22.49,1.58.33.37.73.55,1.21.55s.88-.18,1.21-.55c.32-.37.49-.9.49-1.6s-.16-1.2-.49-1.57c-.32-.37-.73-.55-1.21-.55s-.88.18-1.21.55c-.33.37-.49.89-.49,1.58Z"
                />
                <path
                    fill="#fff"
                    d="m290.53,93.19h-1.84v-6.95h1.71v.99c.29-.47.55-.77.79-.92.23-.15.5-.22.79-.22.42,0,.82.12,1.21.35l-.57,1.6c-.31-.2-.6-.3-.86-.3s-.48.07-.65.21c-.18.14-.32.4-.42.77-.1.37-.15,1.15-.15,2.33v2.15Z"
                />
                <path
                    fill="#fff"
                    d="m295.9,83.6v3.53c.59-.69,1.3-1.04,2.13-1.04.42,0,.8.08,1.14.24.34.16.6.36.77.6.17.24.29.51.35.81.06.3.09.76.09,1.38v4.08h-1.84v-3.67c0-.73-.04-1.19-.1-1.39s-.19-.35-.37-.47c-.18-.12-.4-.17-.66-.17-.3,0-.58.07-.82.22-.24.15-.42.37-.53.67-.11.3-.17.74-.17,1.32v3.48h-1.84v-9.59h1.84Z"
                />
                <path
                    fill="#fff"
                    d="m301.77,89.62c0-.61.15-1.2.45-1.77.3-.57.73-1.01,1.28-1.31s1.17-.45,1.85-.45c1.05,0,1.91.34,2.58,1.02.67.68,1.01,1.54,1.01,2.59s-.34,1.92-1.02,2.61c-.68.69-1.53,1.04-2.56,1.04-.64,0-1.24-.14-1.82-.43-.58-.29-1.02-.71-1.32-1.27s-.45-1.23-.45-2.03Zm1.88.1c0,.69.16,1.22.49,1.58.33.37.73.55,1.21.55s.88-.18,1.21-.55c.32-.37.49-.9.49-1.6s-.16-1.2-.49-1.57c-.32-.37-.73-.55-1.21-.55s-.88.18-1.21.55c-.33.37-.49.89-.49,1.58Z"
                />
                <path
                    fill="#fff"
                    d="m309.92,89.62c0-.61.15-1.2.45-1.77.3-.57.73-1.01,1.28-1.31s1.17-.45,1.85-.45c1.05,0,1.91.34,2.58,1.02.67.68,1.01,1.54,1.01,2.59s-.34,1.92-1.02,2.61c-.68.69-1.53,1.04-2.56,1.04-.64,0-1.24-.14-1.82-.43-.58-.29-1.02-.71-1.32-1.27s-.45-1.23-.45-2.03Zm1.88.1c0,.69.16,1.22.49,1.58.33.37.73.55,1.21.55s.88-.18,1.21-.55c.32-.37.49-.9.49-1.6s-.16-1.2-.49-1.57c-.32-.37-.73-.55-1.21-.55s-.88.18-1.21.55c-.33.37-.49.89-.49,1.58Z"
                />
                <path
                    fill="#fff"
                    d="m324.89,93.19h-1.71v-1.02c-.28.4-.62.69-1,.89s-.78.29-1.17.29c-.8,0-1.48-.32-2.05-.96-.57-.64-.85-1.54-.85-2.69s.28-2.07.83-2.69c.55-.61,1.25-.92,2.1-.92.78,0,1.45.32,2.01.97v-3.45h1.84v9.59Zm-4.91-3.62c0,.74.1,1.28.31,1.61.3.48.71.72,1.24.72.42,0,.78-.18,1.08-.54.3-.36.45-.9.45-1.61,0-.8-.14-1.37-.43-1.72-.29-.35-.66-.53-1.11-.53s-.8.17-1.1.52-.44.86-.44,1.55Z"
                />
                <path
                    fill="#fff"
                    d="m212.57,77.14v-9.59h3.6c.73,0,1.32.1,1.76.29.44.19.79.49,1.04.9.25.4.38.83.38,1.27,0,.41-.11.8-.33,1.16-.22.36-.56.65-1.01.88.58.17,1.03.46,1.34.87.31.41.47.89.47,1.45,0,.45-.09.87-.28,1.25-.19.39-.42.68-.7.89s-.63.37-1.05.47c-.42.11-.94.16-1.55.16h-3.66Zm1.27-5.56h2.07c.56,0,.97-.04,1.21-.11.32-.1.57-.26.73-.48.16-.22.25-.5.25-.84,0-.32-.08-.6-.23-.84-.15-.24-.37-.41-.65-.5-.28-.09-.77-.13-1.46-.13h-1.92v2.9Zm0,4.43h2.39c.41,0,.7-.02.86-.05.29-.05.54-.14.73-.26.2-.12.36-.3.48-.53s.19-.5.19-.81c0-.36-.09-.67-.27-.93-.18-.26-.44-.45-.76-.56-.32-.11-.79-.16-1.4-.16h-2.22v3.3Z"
                />
                <path
                    fill="#fff"
                    d="m226.09,74.91l1.22.15c-.19.71-.55,1.26-1.07,1.66s-1.18.59-1.99.59c-1.02,0-1.82-.31-2.42-.94s-.89-1.5-.89-2.63.3-2.08.9-2.72c.6-.65,1.38-.97,2.34-.97s1.69.32,2.28.95c.59.63.88,1.52.88,2.67,0,.07,0,.17,0,.31h-5.18c.04.76.26,1.35.65,1.75.39.41.87.61,1.45.61.43,0,.8-.11,1.11-.34.31-.23.55-.59.73-1.09Zm-3.87-1.9h3.88c-.05-.58-.2-1.02-.45-1.31-.38-.45-.86-.68-1.46-.68-.54,0-1,.18-1.36.54s-.57.85-.61,1.45Z"
                />
                <path
                    fill="#fff"
                    d="m231.12,76.09l.17,1.04c-.33.07-.63.1-.89.1-.43,0-.76-.07-.99-.2-.24-.14-.4-.31-.5-.53-.1-.22-.14-.68-.14-1.39v-4h-.86v-.92h.86v-1.72l1.17-.71v2.43h1.18v.92h-1.18v4.06c0,.34.02.55.06.65s.11.17.2.23c.09.06.23.09.4.09.13,0,.3-.02.52-.05Z"
                />
                <path
                    fill="#fff"
                    d="m235.03,76.09l.17,1.04c-.33.07-.63.1-.89.1-.43,0-.76-.07-.99-.2-.24-.14-.4-.31-.5-.53-.1-.22-.14-.68-.14-1.39v-4h-.86v-.92h.86v-1.72l1.17-.71v2.43h1.18v.92h-1.18v4.06c0,.34.02.55.06.65s.11.17.2.23c.09.06.23.09.4.09.13,0,.3-.02.52-.05Z"
                />
                <path
                    fill="#fff"
                    d="m240.73,74.91l1.22.15c-.19.71-.55,1.26-1.07,1.66s-1.18.59-1.99.59c-1.02,0-1.82-.31-2.42-.94s-.89-1.5-.89-2.63.3-2.08.9-2.72c.6-.65,1.38-.97,2.34-.97s1.69.32,2.28.95c.59.63.88,1.52.88,2.67,0,.07,0,.17,0,.31h-5.18c.04.76.26,1.35.65,1.75.39.41.87.61,1.45.61.43,0,.8-.11,1.11-.34.31-.23.55-.59.73-1.09Zm-3.87-1.9h3.88c-.05-.58-.2-1.02-.45-1.31-.38-.45-.86-.68-1.46-.68-.54,0-1,.18-1.36.54s-.57.85-.61,1.45Z"
                />
                <path
                    fill="#fff"
                    d="m243.31,77.14v-6.95h1.06v1.05c.27-.49.52-.82.75-.97.23-.16.48-.24.76-.24.4,0,.8.13,1.21.38l-.41,1.09c-.29-.17-.57-.26-.86-.26-.26,0-.49.08-.69.23s-.35.37-.44.64c-.13.42-.2.88-.2,1.37v3.64h-1.18Z"
                />
                <path
                    fill="#fff"
                    d="m256.04,74.6l1.16.15c-.13.8-.45,1.42-.97,1.87-.52.45-1.16.68-1.92.68-.95,0-1.71-.31-2.29-.93-.58-.62-.87-1.51-.87-2.67,0-.75.12-1.41.37-1.97.25-.56.63-.98,1.14-1.27.51-.28,1.06-.42,1.66-.42.75,0,1.37.19,1.85.57.48.38.79.92.92,1.63l-1.14.18c-.11-.47-.3-.82-.58-1.05-.28-.24-.61-.35-1-.35-.59,0-1.08.21-1.45.64-.37.42-.56,1.1-.56,2.02s.18,1.61.54,2.03c.36.42.82.63,1.4.63.46,0,.85-.14,1.16-.43s.51-.72.59-1.31Z"
                />
                <path
                    fill="#fff"
                    d="m257.88,73.67c0-1.29.36-2.24,1.07-2.86.6-.51,1.33-.77,2.18-.77.96,0,1.74.31,2.34.94.61.63.91,1.49.91,2.59,0,.89-.13,1.6-.4,2.11s-.66.91-1.17,1.19c-.51.28-1.07.43-1.68.43-.97,0-1.76-.31-2.36-.94-.6-.62-.9-1.52-.9-2.7Zm1.21,0c0,.89.19,1.56.58,2,.39.44.88.66,1.46.66s1.07-.22,1.46-.67c.39-.44.58-1.12.58-2.03,0-.86-.2-1.51-.59-1.95-.39-.44-.88-.66-1.46-.66s-1.08.22-1.46.66c-.39.44-.58,1.11-.58,1.99Z"
                />
                <path
                    fill="#fff"
                    d="m265.7,77.14v-6.95h1.06v.99c.51-.76,1.25-1.14,2.21-1.14.42,0,.8.08,1.15.23s.61.35.79.59c.17.24.3.53.37.87.04.22.07.6.07,1.14v4.27h-1.18v-4.23c0-.48-.05-.84-.14-1.08s-.25-.43-.49-.57c-.23-.14-.51-.21-.82-.21-.5,0-.93.16-1.3.48-.36.32-.55.92-.55,1.81v3.79h-1.18Z"
                />
                <path
                    fill="#fff"
                    d="m273,77.14v-6.95h1.06v.99c.51-.76,1.25-1.14,2.21-1.14.42,0,.8.08,1.15.23s.61.35.79.59c.17.24.3.53.37.87.04.22.07.6.07,1.14v4.27h-1.18v-4.23c0-.48-.05-.84-.14-1.08s-.25-.43-.49-.57c-.23-.14-.51-.21-.82-.21-.5,0-.93.16-1.3.48-.36.32-.55.92-.55,1.81v3.79h-1.18Z"
                />
                <path
                    fill="#fff"
                    d="m285.09,74.91l1.22.15c-.19.71-.55,1.26-1.07,1.66s-1.18.59-1.99.59c-1.02,0-1.82-.31-2.42-.94s-.89-1.5-.89-2.63.3-2.08.9-2.72c.6-.65,1.38-.97,2.34-.97s1.69.32,2.28.95c.59.63.88,1.52.88,2.67,0,.07,0,.17,0,.31h-5.18c.04.76.26,1.35.65,1.75.39.41.87.61,1.45.61.43,0,.8-.11,1.11-.34.31-.23.55-.59.73-1.09Zm-3.87-1.9h3.88c-.05-.58-.2-1.02-.45-1.31-.38-.45-.86-.68-1.46-.68-.54,0-1,.18-1.36.54s-.57.85-.61,1.45Z"
                />
                <path
                    fill="#fff"
                    d="m292.25,74.6l1.16.15c-.13.8-.45,1.42-.97,1.87-.52.45-1.16.68-1.92.68-.95,0-1.71-.31-2.29-.93-.58-.62-.87-1.51-.87-2.67,0-.75.12-1.41.37-1.97.25-.56.63-.98,1.14-1.27.51-.28,1.06-.42,1.66-.42.75,0,1.37.19,1.85.57.48.38.79.92.92,1.63l-1.14.18c-.11-.47-.3-.82-.58-1.05-.28-.24-.61-.35-1-.35-.59,0-1.08.21-1.45.64-.37.42-.56,1.1-.56,2.02s.18,1.61.54,2.03c.36.42.82.63,1.4.63.46,0,.85-.14,1.16-.43s.51-.72.59-1.31Z"
                />
                <path
                    fill="#fff"
                    d="m297.01,76.09l.17,1.04c-.33.07-.63.1-.89.1-.43,0-.76-.07-.99-.2-.24-.14-.4-.31-.5-.53-.1-.22-.14-.68-.14-1.39v-4h-.86v-.92h.86v-1.72l1.17-.71v2.43h1.18v.92h-1.18v4.06c0,.34.02.55.06.65s.11.17.2.23c.09.06.23.09.4.09.13,0,.3-.02.52-.05Z"
                />
                <path
                    fill="#fff"
                    d="m302.7,74.91l1.22.15c-.19.71-.55,1.26-1.07,1.66s-1.18.59-1.99.59c-1.02,0-1.82-.31-2.42-.94s-.89-1.5-.89-2.63.3-2.08.9-2.72c.6-.65,1.38-.97,2.34-.97s1.69.32,2.28.95c.59.63.88,1.52.88,2.67,0,.07,0,.17,0,.31h-5.18c.04.76.26,1.35.65,1.75.39.41.87.61,1.45.61.43,0,.8-.11,1.11-.34.31-.23.55-.59.73-1.09Zm-3.87-1.9h3.88c-.05-.58-.2-1.02-.45-1.31-.38-.45-.86-.68-1.46-.68-.54,0-1,.18-1.36.54s-.57.85-.61,1.45Z"
                />
                <path
                    fill="#fff"
                    d="m309.83,77.14v-.88c-.44.69-1.09,1.03-1.94,1.03-.55,0-1.06-.15-1.53-.46-.46-.31-.82-.73-1.08-1.28-.25-.55-.38-1.18-.38-1.89s.12-1.32.35-1.89c.23-.56.58-1,1.04-1.3.46-.3.98-.45,1.55-.45.42,0,.79.09,1.12.27.33.18.59.41.8.69v-3.44h1.17v9.59h-1.09Zm-3.72-3.47c0,.89.19,1.55.56,2,.38.44.82.66,1.33.66s.95-.21,1.31-.63c.36-.42.54-1.06.54-1.93,0-.95-.18-1.65-.55-2.09-.37-.44-.82-.67-1.35-.67s-.96.21-1.31.64-.53,1.1-.53,2.02Z"
                />
                <path fill="#fff" d="m316.51 68.91v-1.35h1.18v1.35h-1.18zm0 8.24v-6.95h1.18v6.95h-1.18z" />
                <path
                    fill="#fff"
                    d="m319.34,77.14v-6.95h1.06v.99c.51-.76,1.25-1.14,2.21-1.14.42,0,.8.08,1.15.23s.61.35.79.59c.17.24.3.53.37.87.04.22.07.6.07,1.14v4.27h-1.18v-4.23c0-.48-.05-.84-.14-1.08s-.25-.43-.49-.57c-.23-.14-.51-.21-.82-.21-.5,0-.93.16-1.3.48-.36.32-.55.92-.55,1.81v3.79h-1.18Z"
                />
            </g>
        </svg>
    );
}
