import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { DefaultButton, IButtonStyles, IconButton, IIconProps, makeStyles, Modal, Stack, Text, useTheme } from '@fluentui/react';
// import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { createContext } from './createContext';
import { PageTitle } from '../Components/LayoutElements';
import { FaCheckCircle } from 'react-icons/fa'

const [useCtx, Provider] = createContext<{
    //
    isSignupOpen: boolean;
    openSignUp: () => void;
    closeSignUp: () => void;
    hasBeenOpen: boolean;
}>();

export const useSignupContext = useCtx;

const cancelIcon: IIconProps = { iconName: 'Cancel' };

export const SignupContext: React.FC = ({ children }) => {
    const [isSignupOpen, setIsSignupOpen] = useState(false);
    
    const [successfullyAddedCustomer, setSuccessfullyAddedCustomer] = useState(false);
    const [hasBeenOpen, setHasBeenOpen] = useState(false);

    const theme = useTheme();
    const classes = getCardSectionClasses();
    const buttonClasses = getButtonStyles();
    const { pathname } = useLocation();

    const signup = useMemo(() => {
        return pathname.toLowerCase().endsWith('/signup');
    }, [pathname]);

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: theme.palette.neutralPrimary,
            // marginLeft: '4px',
            marginTop: '4px',
            marginRight: '4px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };

    const closeSignUp = useCallback(() => {
        setIsSignupOpen(false);
    }, []);

    const openSignUp = useCallback(() => {
        setIsSignupOpen(true);
        setHasBeenOpen(true)
    }, []);

    useEffect(() => {
        if (signup && !isSignupOpen && !hasBeenOpen) {
            openSignUp()
        }
    }, [hasBeenOpen, isSignupOpen, openSignUp, signup]);

    const firstRender = useRef(true);

    useEffect(() => {
        if (signup && !firstRender.current) {
            setHasBeenOpen(false);
        } 
        firstRender.current = true;
    }, [signup]);


    return (
        <Provider value={{ isSignupOpen, hasBeenOpen, openSignUp, closeSignUp }}>
            {children}
            <Modal isOpen={isSignupOpen} onDismiss={closeSignUp} isBlocking={true} layerProps={{ styles: { root: { width: 600 } } }}>
                {/* <Modal isOpen={isModalOpen} onDismiss={hideModal} isBlocking={true} > */}
                <Stack horizontal horizontalAlign="end">
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={closeSignUp} />
                </Stack>
                {/* <Stack
                    styles={{
                        root: {
                            padding: '0 20px 20px',
                            // Cancel out styling from WordPress resources
                            'input[type=text], input[type=email], input[type=number]': {
                                color: theme.semanticColors.inputText,
                                padding: 0,
                                height: 'unset',
                                backgroundColor: 'unset',
                            },
                        },
                    }}
                >
                    
                </Stack> */}
            </Modal>
            <Modal
                isOpen={successfullyAddedCustomer}
                onDismiss={() => setSuccessfullyAddedCustomer(false)}
                isBlocking={false}
                layerProps={{ styles: { root: { width: 600 } } }}
            >
                <Stack tokens={{ padding: 20 }} horizontalAlign="center">
                    <PageTitle title={'Thank you!'} />
                    {/* <FontAwesomeIcon icon={{iconName: "circle", prefix: "fab"}} className={classes.successCheckMark} /> */}
                    <FaCheckCircle className={classes.successCheckMark}  />
                    <Text variant="xLarge">Your submission has been recieved and we will contact you soon.</Text>
                    <DefaultButton
                        text="OK"
                        onClick={() => setSuccessfullyAddedCustomer(false)}
                        styles={{
                            root: [buttonClasses.root, { marginTop: '40px !important' }],
                            rootHovered: buttonClasses.hover,
                            label: buttonClasses.label,
                        }}
                    />
                </Stack>
            </Modal>
        </Provider>
    );
};

const getButtonStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#00C160',
        borderWidth: 0,
        height: 32,
        width: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: 40,
        minWidth: 130,
        borderRadius: 25,
        boxShadow:
            '0 0.18rem 0.32rem rgba(0, 0, 0, 0.1), 0 0.32rem 0.64rem rgba(0, 0, 0, 0.1), 0 0.64rem 1.28rem rgba(0, 0, 0, 0.1), 0 1.28rem 2.4rem rgba(0, 0, 0, 0.03)',
    },
    hover: {
        backgroundColor: '#009148',
    },
    label: {
        color: 'white',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: '1px',
    },
}));

const getCardSectionClasses = makeStyles((theme) => ({
    root: [
        'card',
        {
            height: '100%',
            minHeight: 250,
            maxHeight: 290,
            borderRadius: 4,
            padding: theme.spacing.l1,
            paddingTop: theme.spacing.m,
            backgroundColor: theme.semanticColors.bodyBackground,
        },
    ],
    cardHeader: [
        'card_header',
        {
            // height: 100,
            paddingTop: 4,
            '.icon_container': {
                boxShadow:
                    '0 0.18rem 0.32rem rgba(0, 0, 0, 0.0), 0 0.32rem 0.64rem rgba(0, 0, 0, 0.07), 0 0.64rem 1.28rem rgba(0, 0, 0, 0.03), 0 1.28rem 2.4rem rgba(0, 0, 0, 0.03)',
                borderRadius: '21%',
            },
        },
    ],
    cardDivider: [
        'card_divider',
        {
            width: '100%',
            height: 1,
            backgroundColor: theme.semanticColors.bodyDivider,
            opacity: 0.9,
        },
    ],
    cardContent: [
        'card_content',
        {
            width: '100%',
            maxHeight: '100%',
            height: '100%',
            flexBasix: '100%',
            overflowY: 'hidden',
            // minHeight: "50%",
            // height: "fit-content",
            // flexShrink: 0,
        },
    ],
    successCheckMark: [
        'success-checkmark',
        {
            width: '80px !important',
            height: '80px !important',
            marginBottom: 20,
            '*': {
                fill: '#00C160',
            },
        },
    ],
}));
