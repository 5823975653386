import React from 'react';
import { IGetProductButtonProps } from '../../types/IGetProductButtonProps';

export const SuiteManagerDeploymentButton: React.FC<IGetProductButtonProps> = ({ textClassName, className, children, open }) => {
    return (
        <button
            className={className}
            style={{
                backgroundColor: '#00c160',
                border: 'none',
                outline: 'none',
                color: 'white',
                borderRadius: 25,
                padding: '13px 29px',
                fontSize: 14,
                fontFamily: 'Montserrat',
                fontWeight: 700,
                cursor: 'pointer',
            }}
            onClick={open}
        >
            <span className={textClassName}>{children}</span>
        </button>
    );
};
