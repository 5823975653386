import { apiCalls } from '../api';
import { AccountContact } from '../generated/data-contracts';

export async function UpsertCustomer(accountContact: AccountContact) {
    console.log('🚀 ~ SubmitRegistration ~ registrationData', accountContact);
    await apiCalls.customerCreate(accountContact);
}

export function GetCustomer() {
    return apiCalls.customerList().then(response => response.data);
}

export function CreateTimeTrialDatabase() {
    return apiCalls.trialTimeCreate().then(response => response.data);
}