import { createContext as createCtx, useContext } from 'react';

export function createContext<ContextType>(initialValue?: ContextType ) {
    const ctx = createCtx(initialValue);

    function useCtx() {
        const c = useContext(ctx);
        if (!c) 
            throw new Error("useCtx must be inside a Provider with a value");
        
        return c;
    }
    
    return [useCtx, ctx.Provider, ctx] as const;
};
