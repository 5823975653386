import React from 'react';
import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Callout, Persona, PersonaSize, Stack, Text, useTheme, Link as FluentLink } from '@fluentui/react';
import { Link } from 'react-router-dom';

export const UserPersona: React.FC = () => {
    const theme = useTheme();
    const msalCtx = useMsal();

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Stack
                verticalAlign="center"
                horizontalAlign="center"
                styles={{
                    root: {
                        width: 'var(--topbar_height)',
                        height: 'var(--topbar_height)',
                        cursor: 'pointer',
                        marginRight: -8,
                    },
                }}
            >
                <Persona
                    id="userpersona"
                    text={(msalCtx as any).accounts?.[0]?.name}
                    // size={PersonaSize.size32}
                    styles={{ root: { userSelect: 'none' } }}
                    size={PersonaSize.size40}
                    hidePersonaDetails={true}
                    onClick={() => setIsOpen(s => !s)}
                    initialsColor={isOpen ? theme.palette.neutralPrimaryAlt : undefined}
                />
                {isOpen && (
                    <Callout
                        gapSpace={11}
                        target={'#userpersona'}
                        onDismiss={() => setIsOpen(false)}
                        setInitialFocus
                        calloutMaxWidth={350}
                        isBeakVisible={false}
                        styles={{ root: { borderRadius: 20, overflow: 'hidden' } }}
                    >
                        <Stack horizontal tokens={{ childrenGap: theme.spacing.l1, padding: theme.spacing.l1 }}>
                            <Stack verticalAlign="center" horizontalAlign="center">
                                <Persona text={(msalCtx as any).accounts?.[0]?.name} size={PersonaSize.size72} hidePersonaDetails={true} />
                            </Stack>
                            <Stack grow verticalAlign="center">
                                <Text variant="xLarge">{(msalCtx as any).accounts?.[0]?.name ?? ''}</Text>
                                <Text variant="medium">{(msalCtx as any).accounts?.[0]?.username ?? ''}</Text>
                                <Link to="/userprofile" onFocus={e => e.target.blur()}>
                                    <FluentLink>
                                        <Text>Edit profile</Text>
                                    </FluentLink>
                                </Link>
                                <FluentLink onClick={() => msalCtx.instance.logout()}>
                                    <Text>Log out</Text>
                                </FluentLink>
                            </Stack>
                        </Stack>
                    </Callout>
                )}
            </Stack>
        </>
    );
};
