import React, { Suspense, useMemo } from 'react';

export type ErrorInfo = { componentStack: string };
export interface ErrorUiProps {
    error: Error,
    errorInfo: ErrorInfo,
    resetError: () => void;
    [otherProps: string]: any,
}

export interface IErrorBoundaryProps {
    loggingFn?: (error: Error, errorInfo: ErrorInfo) => void;
    errorComponent?: React.FC<ErrorUiProps>
}

export interface IErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    errorInfo: ErrorInfo | null;
}
// export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
        this.resetError = this.resetError.bind(this);
    }

    static getDerivedStateFromError(error: Error) {
        // console.log('ErrorBoundary -> componentDidCatch -> error, errorInfo', error);
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        console.error('ErrorBoundary -> componentDidCatch -> error, errorInfo', error, errorInfo);
        if (this.props.loggingFn) {
            this.props.loggingFn(error, errorInfo);
        }
        this.setState({errorInfo})
    }

    resetError() {
        this.setState({ hasError: false, error: null, errorInfo: null });
    }

    render() {
        if (this.state.hasError) {
            if (this.props.errorComponent) {
                const Component = this.props.errorComponent;
                return <Component error={this.state.error!} errorInfo={this.state.errorInfo!} resetError={this.resetError}/>
            }
            return (
                <>
                    <h1>Something went wrong.</h1>
                    <button onClick={this.resetError}>Try again</button>
                </>
            );
        }

        return this.props.children;
    }
}

interface ISafeSuspense {
    fallback?: React.FC | React.ReactChild,
    errorComponent?: React.FC<ErrorUiProps>
}

export const SafeSuspense: React.FC<ISafeSuspense> = ({
    errorComponent,
    fallback,
    children
}) => {
    const Fallback = useMemo(() => {
        if(typeof fallback === "function") {
            const FallbackComponent = fallback;
            return <FallbackComponent />
        }
        if(!fallback) {
            return <div>loading...</div>
        }
        return fallback;
    }, [fallback])
    return (
        <ErrorBoundary errorComponent={errorComponent}>
            <Suspense fallback={Fallback}>
                {children}
            </Suspense>
        </ErrorBoundary>
    )
}